.sideber{
    width: 14rem; 
    height: calc(150vh - 52px);
    background-color: #1e1e2d;
    position: fixed;
    top: 0;
    z-index:2;
}
.sideber img{
    width: 220px;
}
.sideber ul{
    list-style: none;
    padding: 1rem 2rem;
    margin: 0px;
    padding-top: 50px;
}
.sideber li{
    margin-bottom: 2rem;
}

a{
    text-decoration: none;
    color: var(--secon-color);
}