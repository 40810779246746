.mainContainer{
    padding-top: 50px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: auto;
}
.contentHome{
    width: 70%;
    margin-right: 20px;
}
.imageHome{
    width: 30%;
}
.mainPortfolio{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 20px;
    padding: 30px;
}

.mainContainerBlog{
    width: 80%;
    height: 100%; 
    margin: auto;
    padding-top: 10px;
    padding-bottom: 20px;
}
.mainContainerBanner{
    margin-top:60px;
    width:100%;
    display: flex;
    padding-bottom: 100px;
    align-items: center;
    justify-content: space-between; 
}
.firstPostBlog{
    width: 68%;
}
.seconPostBlog{
    width: 30%;
}
.firstImage{
    height: 600px;
    border-radius: 30px;
}

.secondImage{
    height:300px;
    border-radius: 30px;
}

.mainContainerContact{
    padding-top: 50px;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background-color: var(--leading-color);
    padding: 35;
    border-radius: 10px;
}
.informationPerson{
    min-width:400px;
}
.informationPersonEmail{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.informationSocialMedia{
    display: flex;
}

.contactForm {
    display: flex;
    flex-direction: column;
    min-width:500px;
    height: auto;
}
@media (max-width: 1000px){
    .mainPortfolio{
      grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 890px){
    .mainContainerContact{
        width: 80%;
        flex-direction: column;
        padding-top: 130px;
    }
    .contactForm{
        min-width: 100%;
    }
    .mainContainerBlog{
        padding-top: 50px;
    }
}
@media (max-width: 820px){
    .mainPortfolio{
      grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 772px){
    .mainContainerBanner{
        flex-direction: column;
    }
    .firstPostBlog{
        width: 100%;
    }
    .seconPostBlog{
        width: 100%;
    }
}
@media (max-width: 767px) {
    /* Sobre mi */
    .mainContainer{
      flex-direction: column;
    }
    .contentHome{
        width: 100%;
    }
    .imageHome{
        width: 100%;
    }
}

@media (max-width: 566px){
    .mainPortfolio{
      grid-template-columns: repeat(1, 1fr);
    }
}