.menu {
    border-bottom: solid 1px rgba(34, 34, 34, 0.2);
    background-color: var(--leading-color);
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    z-index: 1;
}
.container {
    display: flex;
    justify-content: flex-end;
}
.profileIcon {
    display: flex;
    padding: 20px;
    cursor:pointer;
}
.profileIcon img{
    width: 50px;
    border-radius: 50px;
}
.pageTitle{
    padding-left: 250px;
}

.dropdownMenu {
    position: absolute;
    top: 80px;
    min-width: 12rem;
    padding: 0.5rem;
    background-color: white;
    border-radius: 0.375rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menuSections{
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.dropdownMenu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdownMenu li,
.menuSections li {
    padding: 0.5rem;
}

.dropdownMenu a,
.menuSections a {
    display: block;
    color: var(--text-color-leading);
    text-decoration: none;
}